import * as React from 'react';
import qs from 'qs';

export interface Props {
  pageId: 'HP' | 'Israeli' | 'International' | 'New_Songs';
}

const Cooladata = (props: Props) => {
  const [isMobile, setIsMobile] = React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    const mediaQueryList = window.matchMedia('(max-width: 767px)');
    setIsMobile(mediaQueryList.matches);
  }, []);

  if (isMobile !== undefined && process.env.ENV === 'production') {
    const { pageId } = props;
    const srcBase = 'https://mobileapp.mako.co.il/metricsCall.html';
    const channelId = 'f6750a2610f26110VgnVCM1000005201000aRCRD';
    const mako_ExternalProjectID = 'EX_hitlist';
    const vcmId = pageId;
    const platform = isMobile ? 'mobile' : 'web';
    const isMainPage = vcmId === 'HP';
    const contentType = isMainPage ? vcmId : 'content';
    const ref = isMainPage ? document.referrer : '';

    const params = {
      channelId,
      mako_ExternalProjectID,
      platform,
      vcmId,
      contentType,
      ref,
    };
    const src = `${srcBase}?${qs.stringify(params)}`;

    return (
      // putting styles inside an <object /> or an <embed />
      // breaks them in chrome no matter how you insert them
      <object type="text/html" data={src} width="0" height="0" style={{ position: 'absolute' }} />
    );
  }

  return null;
};

export default Cooladata;
