import React from 'react';
import { Helmet } from 'react-helmet-async';

import { cdnUrl } from 'src/utils';
import { useAppSelector } from 'src/hooks/store';
import { selectChartOfTheYear } from 'src/store/chart-of-the-year/selectors';

import faviconUrl from 'src/assets/favicon/favicon.png';

const Head = () => {
  const chart = useAppSelector(selectChartOfTheYear);
  const SEO = {
    title: chart.meta?.title ?? 'Mako Charts 2023',
    description: chart.meta?.description ?? '',
  };
  const OG = {
    title: chart.meta?.title ?? 'Mako Charts 2023',
    description: chart.meta?.description ?? '',
    image: cdnUrl(chart.meta?.imageUrl),
  };

  return (
    <Helmet>
      <title>{SEO.title}</title>
      <meta name="description" content={SEO.description} />
      <meta property="og:title" content={OG.title} />
      <meta property="og:description" content={OG.description} />
      <meta property="og:image" content={OG.image} />
      <link rel="icon" type="image/png" href={faviconUrl} />
    </Helmet>
  );
};

export default Head;
