import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as chartOfTheYearActions from '../chart-of-the-year/actions';
import * as chartActions from '../chart/actions';

interface UI {
  headerPosition: 'normal' | 'fixed';
  saveChartMsgVisible: boolean;
  isChartDataFetching: boolean;
}

const initialState: UI = {
  headerPosition: 'normal',
  saveChartMsgVisible: false,
  isChartDataFetching: false,
};

export const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setHeaderPosition: (state, action: PayloadAction<UI['headerPosition']>) => {
      state.headerPosition = action.payload;
    },
    setSaveChartMsgVisible: (state, action: PayloadAction<boolean>) => {
      state.saveChartMsgVisible = action.payload;
    },
    setIsChartDataFetching: (state, action: PayloadAction<boolean>) => {
      state.isChartDataFetching = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(chartOfTheYearActions.getChart.pending, (state) => {
      state.isChartDataFetching = true;
    });
    builder.addCase(chartOfTheYearActions.getChart.rejected, (state) => {
      state.isChartDataFetching = false;
    });
    builder.addCase(chartOfTheYearActions.getChart.fulfilled, (state) => {
      state.isChartDataFetching = false;
    });
    builder.addCase(chartActions.getChart.pending, (state) => {
      state.isChartDataFetching = true;
    });
    builder.addCase(chartActions.getChart.rejected, (state) => {
      state.isChartDataFetching = false;
    });
    builder.addCase(chartActions.getChart.fulfilled, (state) => {
      state.isChartDataFetching = false;
    });
  },
});

export const { setHeaderPosition, setSaveChartMsgVisible, setIsChartDataFetching } = ui.actions;
export default ui.reducer;
