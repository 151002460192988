import React from 'react';
import { Routes, Route } from 'react-router-dom';

import 'src/styles/index.scss';

import { routes } from 'src/constants';
import Main from 'src/components/pages/Main';
import AnnualChart from 'src/components//pages/AnnualChart';

export const App = () => {
  return (
    <Routes>
      <Route index path={routes.ROOT} element={<Main />} />
      <Route index path={routes.ANNUAL_CHART} element={<AnnualChart />} />
      <Route path={routes.MAIN} element={<Main />} />
    </Routes>
  );
};
