import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IModel as SongModel } from '@api/models/song/interface';
import { IModel } from '@api/models/chart-of-the-year/interface';
import * as actions from './actions';

export type IChartOfTheYear = {
  year: number;
  publishDate?: string;
  title?: string;
  meta?: IModel['meta'];
  items?: SongModel[];
};

const initialState: IChartOfTheYear = {
  year: 1800,
};

export const chartOfTheYear = createSlice({
  name: 'chartOfTheYear',
  initialState,
  reducers: {
    initChartOfTheYear: (state, action: PayloadAction<actions.Init>) => {
      state.publishDate = action.payload.publishDate;
      state.title = action.payload.title;
      state.items = action.payload.items;
      state.year = action.payload.year;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.getChart.fulfilled, (state, action) => {
      state.publishDate = action.payload.publishDate;
      state.title = action.payload.title;
      state.items = action.payload.items;
      state.year = action.payload.year;
      state.meta = action.payload.meta;
    });
  },
});

export const { initChartOfTheYear } = chartOfTheYear.actions;
export default chartOfTheYear.reducer;
