import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';

import cn from 'classnames';

import { isPreview } from 'src/utils';
import { routes } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { selectChartOfTheYear } from 'src/store/chart-of-the-year/selectors';
import { getChart } from 'src/store/chart-of-the-year/actions';

import Head from './Head';

import css from './AnnualChart.module.scss';

type Props = {
  className?: string;
};

const AnnualChart = (props: Props) => {
  const { className = '' } = props;

  const location = useLocation();
  const isPreviewMode = isPreview(location.search);
  const { year } = useParams();
  const dispatch = useAppDispatch();
  const chart = useAppSelector(selectChartOfTheYear);

  React.useEffect(() => {
    /**
     * fetch annual chart if there is no data in redux,
     * it can be in 2 cases:
     * - when user was on weekly chart and click on "go to annual chart" button
     * - when user on the annual chart page and select another annual chart in the dropdown
     */

    if (chart.year !== Number(year ?? '1900')) {
      dispatch(getChart({ isPreviewMode, year: Number(year ?? '1900') }));
    }
  }, []);

  return (
    <>
      <Head />
      <div className={cn(css.annualChart, className)}>
        <Link to={routes.ROOT}>Back</Link>
        <div>{chart.year}</div>
        <div className={css.songs}>
          <ol>
            {chart.items?.map((item) => (
              <li key={item.uuid}>{`${item.altTitle || item.title} - ${item.altArtist || item.artist}`}</li>
            ))}
          </ol>
        </div>
      </div>
    </>
  );
};

export default AnnualChart;
